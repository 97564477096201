import React, { useEffect, useRef, useState } from 'react';
import { Button, Header, Input } from '../../components';
import Alert from '../../components/Alert';
import { IoCalendarOutline } from "react-icons/io5";
import { BsCalendar4Range } from "react-icons/bs";
import Loading from '../../components/Loading';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaRegFileExcel } from 'react-icons/fa6';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactToPrint from 'react-to-print';
import { FaPrint } from 'react-icons/fa';
import Icon from '../../data/icon.png';
import NumberFormat from '../../components/NumberFormat';
import Select from 'react-select';

const SituationAgent = () => {
  const [errors, setErrors] = useState(null);

  const [showSituation, setShowSituation] = useState(false);
  const [initial, setInitial] = useState(false);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [inputValueAgent, setValueAgent] = useState('');

  const [loadingSituation, setLoadingSituation] = useState(false);

  const situationRef = useRef();

  const situationPrintRef = useRef();


  const [situation, setSituation] = useState({
    totalTicket: 0,
    totalRemboursement: 0,
    solde: 0,
    totalPaiement: 0,
    totalDechargement: 0,
    soldeRestant: 0,
    agent: {},
    start: '',
    end: '',
    typeSit:'',
  });


  const [req, setReq] = useState({
    DateDebut: '',
    DateFin: '',
    agent: '',
  });
  const { setToken, user } = useStateContext();
  const navigate = useNavigate();



  const getSituation = () => {
    setInitial(true);


    setInitial(false);
    setShowSituation(true);
    setLoadingSituation(true);
    setErrors(null);
    axiosClient.get('/situation?DateDebut=' + req.DateDebut + '&DateFin=' + req.DateFin + '&agent=' + req.agent)
      .then((response) => {
        console.log(response.data);
        setSituation({
          ...situation, totalTicket: response.data.totalTicket, totalRemboursement: response.data.totalRemboursement,
          solde: response.data.solde,
          start: response.data.start,
          end: response.data.end,
          agent:response.data.agent,
        });
        setLoadingSituation(false);
      }).catch(err => {
        const response = err.response;
        setShowSituation(false);
        setLoadingSituation(false);
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            toast.error('Une erreur est survenue!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })

  }
  useEffect(() => {
    if (user.role && (user.role.name === 'admin' )) {
      getAgents();
    }
  }, [])
  const handleInputChangeAgent = value => {
    setValueAgent(value);
  };

  // handle selection


  // handle selection
  const handleChangeAgent = value => {
    setSelectedAgent(value);
    setReq({ ...req, agent: value.id });

  }
  const getAgents = () => {
    axiosClient.get('/users')
      .then((data) => {
        setAgents(data.data.users);
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            toast.error('Une erreur est survenue!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })
  }


  return (
    <>
      <Header title="Situation Agent " />
      {errors &&
        Object.keys(errors).map(key => (
          <Alert key={key} message={errors[key][0]} />
        ))
      }
      <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between items-center'>
        <Input label="Date debut" type="date" value={req.DateDebut} onChange={ev => setReq({ ...req, DateDebut: ev.target.value })} required="required" />
        <Input label="Date fin" type="date" value={req.DateFin} onChange={ev => setReq({ ...req, DateFin: ev.target.value })} required="required" />
        
        {user.role && (user.role.name === 'admin' || user.role.name === 'controleur'  || user.role.name === 'dist') ?
          <>

            <div className="flex-col w-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">Agent <span className='text-red-500'> *</span></label>
              <Select className='mt-1 outline-1 ' placeholder="Agent" cacheOptions defaultOptions value={selectedAgent} getOptionLabel={e => e.agence.nom+' | '+e.nom} getOptionValue={e => e.id} options={agents} onInputChange={handleInputChangeAgent} onChange={handleChangeAgent} />
            </div>
          </>
          :
          <></>

        }

        <div className='mt-8'>
          <Button onClick={() => getSituation()} color="bg-green-500" icon={<BsCalendar4Range />} size="20" text="Voir" textColor="text-white" />
        </div>
      </div>
      <div className='flex flex-col gap-4 mt-4'>
        {initial ? <></> :

          <>
            {showSituation ?
              <>
                {loadingSituation ? <Loading /> :
                  <>
                    <div className="flex justify-end items-end gap-2">
                      <ReactToPrint
                        trigger={() => {
                          return <Button icon={<FaPrint />} color="ml-1 bg-blue-500" textColor="text-white" text="Imprimer" />
                        }}
                        content={() => situationPrintRef.current}
                        documentTitle='Situation'
                        pageStyle='situation'
                      />

                    </div>
                    <div className="mt-3 w-full overflow-x-auto">
                      <div ref={situationPrintRef} className="p-3">
                        <table className='table-auto'>
                          <thead>
                            <tr>
                              <td>
                                <div className="flex  gap-1">
                                  <img className=" h-32 w-32 " src={Icon} alt="Your Company" />
                                  <div className="flex flex-col gap-3">
                                    <h1 className='font-bold text-xl '>Situation Agent </h1>
                                    <h2 className=' text-sm '>Agence code : {situation.agent.agence.code}</h2>
                                    <h2 className=' text-sm '>Agence : {situation.agent.agence.nom}</h2>
                                    <h2 className=' text-sm '>Agent : {situation.agent.nom}</h2>

                                  </div>


                                </div>
                                <div className="flex w-full my-4 justify-center items-center">
                                  <h1>Situation Agent  du: {situation.start} au: {situation.end}</h1>
                                </div>
                                <div className="flex w-full my-1 justify-center items-center">
                                  
                                </div>
                              </td>
                            </tr>
                          </thead>
                          <tbody>

                            <table ref={situationRef} className='table-auto'>
                              <thead>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan="3">Total Tickets </td>
                                  <td className='font-bold flex justify-end'><NumberFormat valeur={situation.totalTicket} /></td>
                                </tr>
                                <tr>
                                  <td colSpan="3">Total Remboursement </td>
                                  <td className='font-bold flex justify-end'><NumberFormat valeur={situation.totalRemboursement} /></td>
                                </tr>
                                
                                <tr>
                                  <td colSpan="3">Solde </td>
                                  <td className='font-bold flex justify-end'><NumberFormat valeur={situation.solde} /></td>
                                </tr>
                              </tbody>
                            </table>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </>
                }

              </>
              :
              <></>

            }


          </>

        }


      </div>
    </>
  )
}

export default SituationAgent