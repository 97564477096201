import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import Alert from '../../components/Alert'
import { IoIosSearch } from 'react-icons/io';

import { useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';

import Loading from '../../components/Loading';

import Swal from 'sweetalert2';

import Select from 'react-select';
import ActionButton from '../../components/ActionButton';
import { GiCancel } from 'react-icons/gi';
import { FaEye } from 'react-icons/fa';
import { TbTruckDelivery } from 'react-icons/tb';
import ShowColis from './ShowColis';


const Colis = () => {
    const [errors, setErrors] = useState(null);

    const [loading, setLoading] = useState(false);

    const [colis, setColis] = useState(null);

    const { token, setToken, user } = useStateContext();

    const [selectedColis, setSelectedColis] = useState(null);
    const [showColis, setShowColis] = useState(false);


    const tableRef = useRef();
    const [req, setReq] = useState({
        telReceveur: '',
        code: '',
    });
    const navigate = useNavigate();



    useEffect(() => {

    }, [])



    const getColis = () => {
        setLoading(true);
        setColis(null);
        setErrors(null);
        axiosClient.get('/colis/find/rec?code=' + req.code + '&telReceveur=' + req.telReceveur)
            .then(response => {
                setColis(response.data.colis);
                setLoading(false);

            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    const supprimerColis = (Ticket) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de supprimer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/colis/' + Ticket.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getColis();
                    }).catch(err => {
                        const response = err.response;
                        if (response.status === 401) {
                            setToken(null);
                        }
                        else {
                            if (response.status === 422) {
                                setErrors(response.data.errors);
                                setLoading(false);
                                toast.error('Une Erreur est survenue', {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            } else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }

                        }
                    })
            }
        });
    }

    const closeShowColis = () => {
        setShowColis(false);
        setSelectedColis(null);
        getColis();
    }





    return (
        <>
            <Header title="Trouvez un colis" />
            {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
            }
            <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-start items-center'>
                <div><Input label="code" type="text" value={req.code} onChange={ev => setReq({ ...req, code: ev.target.value })} required="required" /></div>
                {/* <Input label="Tel" type="text" value={req.telReceveur} onChange={ev => setReq({ ...req, telReceveur: ev.target.value })} required="required" /> */}

                <div className=' flex gap-2 mt-8'>
                    <Button onClick={() => getColis()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
                </div>
            </div>
            <div className="flex gap-2 flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between ">

                <div className="flex-col w-full gap-2 my-2">

                    <div className='flex w-full mt-3 overflow-auto'>
                        <table ref={tableRef} className='table-auto' >
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Tel Exp</th>
                                    <th>Dest</th>
                                    <th>Tel Rec</th>
                                    <th>Poid</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="6"><Loading /></td></tr></tbody> :
                                <tbody className='text-sm'>
                                    {colis !== null ?
                                        <tr className={colis.etat===0 ? 'bg-red-400' : colis.etat===1 ? 'bg-white' : 'bg-green-300'}>
                                            <td>{colis.type}</td>
                                            <td>{colis.telExpediteur}</td>
                                            <td>{colis.agenceDestination.nom}</td>
                                            <td>{colis.telReceveur}</td>
                                            <td>{colis.poid}</td>
                                            <td>
                                                <div className="flex gap-2">
                                                    <ActionButton onClick={() => {
                                                        setSelectedColis(colis);
                                                        setShowColis(true);
                                                    }} color="bg-green-500" icon={<FaEye />} textColor="text-white" title="Voir" />
                                                    {user.role && (user.role.name === 'admin') ?
                                                        <ActionButton onClick={() => {
                                                            supprimerColis(colis);
                                                        }} color="bg-red-500" icon={<GiCancel />} textColor="text-white" title="Annuler" />
                                                        : <></>

                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        : <></>

                                    }
                                </tbody>
                            }

                        </table>
                    </div>
                </div>
            </div>
            {
                showColis ? <ShowColis editedColis={selectedColis} onClose={closeShowColis} /> : <></>
            }


        </>
    )
}

export default Colis