import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';
import PrintRecipiet from './PrintRecipiet';

const EditTicket = ({ editedTicket,onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken,user } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [agences, setAgences] = useState([]);
    const [selectedAgence, setSelectedAgence] = useState(editedTicket.agenceDestination);
    const [inputValueAgence, setValueAgence] = useState('');
    
    const [showPrint,setShowPrint]=useState(false);
    const [selectedPassager,setSelectedPassager]=useState(null);
    const [ticket, setTicket] = useState({
        passagerId:editedTicket.id,
        montant: editedTicket.montant,
        nom: editedTicket.nom,
        tel: editedTicket.tel,
        nni: editedTicket.nni,
        dateDepart: editedTicket.dateDepart,
        ordreBus: editedTicket.ordreBus,
        poidBagage: editedTicket.poidBagage,
        agenceDestination:editedTicket.agenceDestination,
        montantBagage:editedTicket.montantBagage,
        note:editedTicket.note,
    });

    useEffect(() => {
        getAgences();
    }, []);

    const closePrint=()=>{
        setShowPrint(false);
        setSelectedPassager(null);
        onClose();
    }

    const handleInputChangeAgence = value => {
        setValueAgence(value);
    };

    // handle selection


    // handle selection
    const handleChangeAgence = value => {
        setSelectedAgence(value);
        setTicket({ ...ticket, agenceDestination: value.id });

    }
    
    const navigate = useNavigate();
    const getAgences = () => {
        axiosClient.get('/agences')
            .then((data) => {
                setAgences(data.data.agences);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    const onSubmit = () => {

        setErrors(null);
        setLoading(true);

        axiosClient.post('/ticket/passager/update', ticket)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setSelectedPassager(response.data.passager);
                setShowPrint(true);
                
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }



    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col max-h-screen overflow-y-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto md:w-1/2 lg:w-1/2 xl:w-1/2 rounded-xl'>
                        <div className=''>
                            <Header title="Ticket" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }

                        <>
                            <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                <div className='flex-col w-full justify-evenly '>

                                <div className="flex-col w-full">
                                        <label className="block text-sm font-medium leading-6 text-gray-900">Destination <span className='text-red-500'> *</span></label>
                                        <Select className='mt-1 outline-1 ' placeholder="Agence" cacheOptions defaultOptions value={selectedAgence} getOptionLabel={e => e.nom} getOptionValue={e => e.id} options={agences} onInputChange={handleInputChangeAgence} onChange={handleChangeAgence} />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Date depart" type="date" value={ticket.dateDepart} onChange={ev => setTicket({ ...ticket, dateDepart: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Nom" type="text" value={ticket.nom} onChange={ev => setTicket({ ...ticket, nom: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Tel" type="text" value={ticket.tel} onChange={ev => setTicket({ ...ticket, tel: ev.target.value })} required="required" />
                                    </div>

                                    <div className='mr-2'>
                                        <Input label="NNI" type="text" value={ticket.nni} onChange={ev => setTicket({ ...ticket, nni: ev.target.value })} required="required" />
                                    </div>

                                </div>
                                <div className='flex-col w-full justify-evenly '>
                                    <div className='mr-2'>
                                        <Input label="Montant" readOnly="readOnly" type="number" value={ticket.montant} onChange={ev => setTicket({ ...ticket, montant: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="poidBagage" type="text" value={ticket.poidBagage} onChange={ev => setTicket({ ...ticket, poidBagage: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Montant Bagage" readOnly="readOnly" type="number" value={ticket.montantBagage} onChange={ev => setTicket({ ...ticket, montantBagage: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Ordre Bus" type="text" value={ticket.ordreBus} onChange={ev => setTicket({ ...ticket, ordreBus: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Note" type="text" value={ticket.note} onChange={ev => setTicket({ ...ticket, note: ev.target.value })} />
                                    </div>

                                </div>

                            </div>


                            <div className='flex  justify-end items-end'>
                                {loading ? <Loading /> :
                                    <div className=' flex gap-3 m-2  justify-between'>
                                        {user.role && user.role.name==='admin' ?
                                            <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />
                                            :
                                            <></>

                                        }
                                        <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                                    </div>
                                }
                            </div>
                            {
                                showPrint ? <PrintRecipiet paiement={selectedPassager} onClose={closePrint}/> : <></>
                            }
                        </>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditTicket