import React from 'react';
import { NavLink } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { FaPersonWalkingLuggage,  } from "react-icons/fa6";
import { LuBaggageClaim } from "react-icons/lu";
import { BsTicketPerforatedFill } from 'react-icons/bs';
import { IoCalendar } from 'react-icons/io5';

const AgentRole = () => {
    const activeLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg text-white bg-blue-600 text-md m-2';
    const normalLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
    const { setActiveMenu, screenSize, currentColor } = useStateContext();


    const handleCloseSidebar = () => {
        if (screenSize <= 900) {
            setActiveMenu(false);

        }
    }
    return (
        <div >

            <div>
                <NavLink to={`/voyages`} key='voyages' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaPersonWalkingLuggage />
                    <span className='capitalize mb-2'>
                        Voyages
                    </span>
                </NavLink>
                <NavLink to={`/tickets`} key='tickets' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <BsTicketPerforatedFill />
                    <span className='capitalize mb-2'>
                        Tickets
                    </span>
                </NavLink>
                <NavLink to={`/colis`} key='colis' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <LuBaggageClaim />
                    <span className='capitalize mb-2'>
                        Colis
                    </span>
                </NavLink>
                <NavLink to={`/situations`} key='situations' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <IoCalendar />
                    <span className='capitalize mb-2'>
                        Situation
                    </span>
                </NavLink>
            </div>
        </div>

    )
}

export default AgentRole