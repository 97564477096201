import React from 'react'
import Forbidden from '../data/forbidden403.gif'
const Forbiden = () => {
  return (
    <div className='flex justify-center items-center p-7'>
      <div className='animate-ping absolute h-auto w-auto  inline-flex    bg-yellow-400 opacity-75'>
       <div className='bg-transparent w-auto h-auto'></div>
      </div>
      <img  className="mx-auto h-96 w-auto" src={Forbidden} alt="Your Company" />
      
    </div>
  )
}

export default Forbiden;