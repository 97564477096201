import React, { useEffect, useState } from 'react'
import { BsFillBuildingFill, BsTicketPerforatedFill } from 'react-icons/bs'
import { FaCashRegister, FaRegMoneyBillAlt } from 'react-icons/fa'
import { FiShoppingCart, FiUsers } from 'react-icons/fi'
import { NavLink, useNavigate } from 'react-router-dom'
import { TbMath } from "react-icons/tb";
import { useStateContext } from '../../contexts/ContextProvider'
import { FaFileLines, FaMoneyBill1Wave, FaPersonWalkingLuggage, FaRegCalendarDays } from 'react-icons/fa6'
import { BiArrowToTop } from "react-icons/bi";
import { MdManageHistory, MdTransitEnterexit } from "react-icons/md";
import { CiInboxOut } from "react-icons/ci";
import { FaBuildingColumns } from "react-icons/fa6";
import { GiCash } from "react-icons/gi";
import { IoCalendar, IoGitBranch, IoLogInSharp } from 'react-icons/io5'
import axiosClient from '../../axios-client'
import { toast } from 'react-toastify'
import { LuBaggageClaim } from 'react-icons/lu'

const AdminRole = () => {
    const activeLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg text-white bg-blue-600 text-md m-2';
    const normalLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
    const { setActiveMenu, screenSize, currentColor, setToken } = useStateContext();

    // const [toDo, setToDo] = useState({
    //     totalAli: 0,
    //     totalSor: 0,
    //     totalEnt: 0,
    //     totalDep: 0,
    //     totalPai: 0,
    //     totalDec: 0,
    // });
    const navigate = useNavigate();

    useEffect(() => {
        //getToDo();
    }, [])
    // const getToDo = () => {
    //     axiosClient.get('/toDo')
    //         .then((response) => {

    //             setToDo({
    //                 ...toDo, totalAli: response.data.totalAli, totalSor: response.data.totalSor,
    //                 totalEnt: response.data.totalEnt, totalDep: response.data.totalDep, totalPai: response.data.totalPai,
    //                 soldeRestant: response.data.soldeRestant, totalDec: response.data.totalDec,

    //             });

    //         }).catch(err => {
    //             const response = err.response;

    //             if (response.status === 401) {
    //                 setToken(null);
    //             }
    //             else {
    //                 if (response.status === 422) {

    //                     toast.error('Une erreur est survenue!', {
    //                         position: "top-right",
    //                         autoClose: 5000,
    //                         hideProgressBar: false,
    //                         closeOnClick: true,
    //                         pauseOnHover: true,
    //                         draggable: true,
    //                         progress: undefined,
    //                         theme: "light",
    //                     });
    //                 } else {
    //                     if (response.status === 403) {
    //                         navigate('/403');
    //                     } else {
    //                         if (response.status === 404) {
    //                             navigate('/404');
    //                         }
    //                         else {
    //                             navigate('/500');
    //                         }
    //                     }
    //                 }

    //             }
    //         })

    // }
   
    const handleCloseSidebar = () => {
        if (screenSize <= 900) {
            setActiveMenu(false);

        }
    }
    return (
        < >

            <div>
                <NavLink to={`/agences`} key='users' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaBuildingColumns />
                    <span className='capitalize mb-2'>
                        Agences
                    </span>
                </NavLink>
                <NavLink to={`/users`} key='users' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FiUsers />
                    <span className='capitalize mb-2'>
                        Utilisateurs
                    </span>
                </NavLink>
                <NavLink to={`/voyages`} key='voyages' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <FaPersonWalkingLuggage />
                    <span className='capitalize mb-2'>
                        Voyages
                    </span>
                </NavLink>
                <NavLink to={`/tickets`} key='tickets' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <BsTicketPerforatedFill />
                    <span className='capitalize mb-2'>
                        Tickets
                    </span>
                </NavLink>
                <NavLink to={`/colis`} key='colis' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <LuBaggageClaim />
                    <span className='capitalize mb-2'>
                        Colis
                    </span>
                </NavLink>
                <NavLink to={`/situations`} key='situations' onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                    } style={({ isActive }) => isActive ? { backgroundColor: `${currentColor}` } : { backgroundColor: 'white' }}>
                    <IoCalendar />
                    <span className='capitalize mb-2'>
                        Situation
                    </span>
                </NavLink>
            </div>
        </>
    )
}

export default AdminRole