import { Navigate, createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import GuestLayout from "./layouts/GuestLayout";
import Login from "./pages/Login";
import Forbiden from "./pages/Forbiden";
import NotFound from "./pages/NotFound";
import ServerError from "./pages/ServerError";
import Users from "./pages/User/Users";
import Home from "./pages/Home";


import Agences from "./pages/agences/Agences";
import Passagers from "./pages/Passager/Passagers";
import Colis from "./pages/Colis/Colis";
import Tickets from "./pages/Passager/Tickets";
import SituationAgent from "./pages/situation/SituationAgent";

const router=createBrowserRouter([
    {
        path:'/',
        element:<DefaultLayout/>,
        children:[
            {
                path:'/',
                element:<Navigate to="/home"/>
            },
            {
                path:'/home',
                element:<Home/>
            },
            {
                path:'/agences',
                element:<Agences/>
            },
            {
                path:'/users',
                element:<Users/>
            },
            {
                path:'/voyages',
                element:<Passagers/>
            },
            {
                path:'/tickets',
                element:<Tickets/>
            },
            {
                path:'/colis',
                element:<Colis/>
            },
            {
                path:'/situations',
                element:<SituationAgent/>
            },
            {
                path:'/404',
                element:<NotFound/>
            },
            {
                path:'/403',
                element:<Forbiden/>
            },
            {
                path:'/500',
                element:<ServerError/>
            },
            {
                path:'*',
                element:<NotFound/>
            },
            
            
            
        ]
    },
    {
        path:'/',
        element:<GuestLayout/>,
        children:[
            {
                path:'/login',
                element:<Login/>
            },
            {
                path:'/404',
                element:<NotFound/>
            },
            {
                path:'*',
                element:<NotFound/>
            },
            
            
        ]
    },
    {
        path:'/500',
        element:<ServerError/>
    },
    {
        path:'*',
        element:<NotFound/>
    }
    
    
])

export default router;