import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';
import BlueMessage from '../../components/BlueMessage';
import GreenMessage from '../../components/GreenMessage';
import { TbTruckDelivery } from 'react-icons/tb';
import Swal from 'sweetalert2';

const ShowColis = ({ editedColis, onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken, user } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [agences, setAgences] = useState([]);
    const [selectedAgence, setSelectedAgence] = useState(null);
    const [inputValueAgence, setValueAgence] = useState('');
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [inputValueType, setValueType] = useState('');
    const [showPrint, setShowPrint] = useState(false);
    const [selectedColis, setSelectedColis] = useState(null);

    const [colis, setColis] = useState(editedColis);

    useEffect(() => {
        getAgences();
        getType();
    }, []);
    const closePrint = () => {
        setShowPrint(false);
        setSelectedColis(null);
        onClose();
    }
    const handleInputChangeAgence = value => {
        setValueAgence(value);
    };

    // handle selection


    // handle selection
    const handleChangeAgence = value => {
        setSelectedAgence(value);
        setColis({ ...colis, agenceDestination: value.id });

    }

    const handleInputChangeType = value => {
        setValueType(value);
    };

    // handle selection


    // handle selection
    const handleChangeType = value => {
        setSelectedType(value);
        setColis({ ...colis, type: value.id });

    }

    const navigate = useNavigate();
    const getAgences = () => {
        axiosClient.get('/agences')
            .then((data) => {
                setAgences(data.data.agences);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    const getType = () => {
        axiosClient.get('/typesColis')
            .then((data) => {
                setTypes(data.data.types);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    const livrer = () => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de livrer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4CAF50",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                axiosClient.post('/colis/livrer/' + colis.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        onClose();
                        
                    }).catch(err => {
                        const response = err.response;
                        if (response.status === 401) {
                            setToken(null);
                        }
                        else {
                            if (response.status === 422) {
                                setErrors(response.data.errors);
                                setLoading(false);
                                toast.error('Une Erreur est survenue!', {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            } else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }

                        }
                    })
            }
        });
    }



    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col max-h-screen overflow-y-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto md:w-1/2 lg:w-1/2 xl:w-1/2 rounded-xl'>
                        <div className=''>
                            <Header title="Colis" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                        {colis && colis.etat === 1 ?
                            <BlueMessage message='Etat du colis: Enours' />
                            :
                            colis && colis.etat === 2 ?
                                <GreenMessage message={`Etat du colis: livré par ${colis.livrePar} le :${colis.dateLivraison} `} />
                                :
                                colis && colis.etat === 0 ?
                                    <Alert message='Etat du colis: Annulé' />
                                    :
                                    <></>

                        }

                        <>
                            <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                <div className='flex-col w-full justify-evenly '>
                                    <div className="flex-col w-full">
                                        <Input readOnly="readOnly" label="Source => Destination" type="text" value={`${colis.agenceDepart.nom} => ${colis.agenceDestination.nom}`} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input readOnly="readOnly" label="Type" type="text" value={colis.type}  required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input readOnly="readOnly" label="Date depart" type="text" value={colis.dateDepart} onChange={ev => setColis({ ...colis, dateDepart: ev.target.value })} required="required" />
                                    </div>

                                    <div className='mr-2'>
                                        <Input readOnly="readOnly" label="Tel Expediteur" type="text" value={colis.telExpediteur} onChange={ev => setColis({ ...colis, telExpediteur: ev.target.value })} required="required" />
                                    </div>

                                    <div className='mr-2'>
                                        <Input readOnly="readOnly" label="Tel Receveur" type="text" value={colis.telReceveur} onChange={ev => setColis({ ...colis, telReceveur: ev.target.value })} required="required" />
                                    </div>


                                </div>
                                <div className='flex-col w-full justify-evenly '>
                                    <div className='mr-2'>
                                        <Input readOnly="readOnly" label="poidBagage" type="text" value={colis.poid} onChange={ev => setColis({ ...colis, poid: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Montant" readOnly="readOnly" type="number" value={colis.montant} onChange={ev => setColis({ ...colis, montant: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input readOnly="readOnly" label="Ordre Bus" type="text" value={colis.ordreBus} onChange={ev => setColis({ ...colis, ordreBus: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input readOnly="readOnly" label="Note" type="text" value={colis.note} onChange={ev => setColis({ ...colis, note: ev.target.value })} />
                                    </div>

                                </div>

                            </div>


                            <div className='flex  justify-end items-end'>
                                {loading ? <Loading /> :
                                    <div className=' flex gap-3 m-2  justify-between'>
                                        {colis && colis.agenceDestination.code === user.agence.code ?
                                            <>
                                                {
                                                    colis && colis.etat === 1 ?
                                                        <Button icon={<TbTruckDelivery />} onClick={livrer} color="ml-1 bg-green-500" textColor="text-white" text="Livrer" />
                                                        :
                                                        <></>
                                                }
                                            </>
                                            :
                                            <></>


                                        }
                                        <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                                    </div>
                                }
                            </div>
                            
                        </>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ShowColis