import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';
import PrintColis from './PrintColis';

const AddColis = ({ onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [agences, setAgences] = useState([]);
    const [selectedAgence, setSelectedAgence] = useState(null);
    const [inputValueAgence, setValueAgence] = useState('');
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [inputValueType, setValueType] = useState('');
    const [showPrint,setShowPrint]=useState(false);
    const [selectedColis,setSelectedColis]=useState(null);

    const [colis, setColis] = useState({
        montant: 0,
        telExpediteur: '',
        telReceveur: '',
        dateDepart: '',
        ordreBus: '',
        poid: '',
        agenceDestination: '',
        type:'',
        note:'',


    });

    useEffect(() => {
        getAgences();
        getType();
    }, []);
    const closePrint=()=>{
        setShowPrint(false);
        setSelectedColis(null);
        onClose();
    }
    const handleInputChangeAgence = value => {
        setValueAgence(value);
    };

    // handle selection


    // handle selection
    const handleChangeAgence = value => {
        setSelectedAgence(value);
        setColis({ ...colis, agenceDestination: value.id });

    }

    const handleInputChangeType = value => {
        setValueType(value);
    };

    // handle selection


    // handle selection
    const handleChangeType = value => {
        setSelectedType(value);
        setColis({ ...colis, type: value.id });

    }
    
    const navigate = useNavigate();
    const getAgences = () => {
        axiosClient.get('/agences')
            .then((data) => {
                setAgences(data.data.agences);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    const getType = () => {
        axiosClient.get('/typesColis')
            .then((data) => {
                setTypes(data.data.types);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    const onSubmit = () => {

        setErrors(null);
        setLoading(true);

        axiosClient.post('/ticket/colis/save', colis)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                setSelectedColis(response.data.colis);
                setShowPrint(true);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }



    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col max-h-screen overflow-y-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto md:w-1/2 lg:w-1/2 xl:w-1/2 rounded-xl'>
                        <div className=''>
                            <Header title="Colis" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }

                        <>
                            <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                <div className='flex-col w-full justify-evenly '>

                                    <div className="flex-col w-full">
                                        <label className="block text-sm font-medium leading-6 text-gray-900">Destination <span className='text-red-500'> *</span></label>
                                        <Select className='mt-1 outline-1 ' placeholder="Agence" cacheOptions defaultOptions value={selectedAgence} getOptionLabel={e => e.nom} getOptionValue={e => e.id} options={agences} onInputChange={handleInputChangeAgence} onChange={handleChangeAgence} />
                                    </div>
                                    <div className="flex-col w-full">
                                        <label className="block text-sm font-medium leading-6 text-gray-900">Type <span className='text-red-500'> *</span></label>
                                        <Select className='mt-1 outline-1 ' placeholder="Type" cacheOptions defaultOptions value={selectedType} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={types} onInputChange={handleInputChangeType} onChange={handleChangeType} />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Date depart" type="date" value={colis.dateDepart} onChange={ev => setColis({ ...colis, dateDepart: ev.target.value })} required="required" />
                                    </div>
                                    
                                    <div className='mr-2'>
                                        <Input label="Tel Expediteur" type="text" value={colis.telExpediteur} onChange={ev => setColis({ ...colis, telExpediteur: ev.target.value })} required="required" />
                                    </div>

                                    <div className='mr-2'>
                                        <Input label="Tel Receveur" type="text" value={colis.telReceveur} onChange={ev => setColis({ ...colis, telReceveur: ev.target.value })} required="required" />
                                    </div>
                                    

                                </div>
                                <div className='flex-col w-full justify-evenly '>
                                    <div className='mr-2'>
                                        <Input label="poidBagage" type="text" value={colis.poid} onChange={ev => setColis({ ...colis, poid: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Montant" type="number" value={colis.montant} onChange={ev => setColis({ ...colis, montant: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Ordre Bus" type="text" value={colis.ordreBus} onChange={ev => setColis({ ...colis, ordreBus: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Note" type="text" value={colis.note} onChange={ev => setColis({ ...colis, note: ev.target.value })} />
                                    </div>

                                </div>

                            </div>


                            <div className='flex  justify-end items-end'>
                                {loading ? <Loading /> :
                                    <div className=' flex gap-3 m-2  justify-between'>
                                        <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />
                                        <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                                    </div>
                                }
                            </div>
                            {
                                showPrint ? <PrintColis colis={selectedColis} onClose={closePrint}/> : <></>
                            }
                        </>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddColis