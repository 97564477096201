import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import Alert from '../../components/Alert'
import { IoIosSearch } from 'react-icons/io';

import { useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaRegFileExcel } from 'react-icons/fa6';
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import Loading from '../../components/Loading';
import NumberFormat from '../../components/NumberFormat';
import AddTicket from './AddTicket';
import ActionButton from '../../components/ActionButton';
import Swal from 'sweetalert2';
import { FaCircle, FaEye } from 'react-icons/fa';
//import ShowTicket from './ShowTicket';
import Select from 'react-select';
import AddColis from './AddColis';
import { GiCancel } from 'react-icons/gi';
import EditTicket from './EditTicket';
import EditColis from './EditColis';


const Tickets = () => {
    const [errors, setErrors] = useState(null);

    const [loading, setLoading] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [colis, setColis] = useState([]);
    const [showAddTicket, setShowAddTicket] = useState(false);
    const [showAddColis, setShowAddColis] = useState(false);
    
    const [selectedColis, setSelectedColis] = useState(null);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [showDetailColis, setShowDetailColis] = useState(false);
    const [showDetailTicket, setShowDetailTicket] = useState(false);
    const { token, setToken, user } = useStateContext();
    const [categories, setCategories] = useState([]);
    const [selectedCategorie, setSelectedCategorie] = useState(null);
    const [inputValueCategorie, setValueCategorie] = useState('');
    const [caisses, setCaisses] = useState([]);
    const [selectedCaisse, setSelectedCaisse] = useState(null);
    const [inputValueCaisse, setValueCaisse] = useState('');
    const [showAddDistTicket, setShowAddDistTicket] = useState(false);
    const [agences, setAgences] = useState([]);
    const [selectedAgence, setSelectedAgence] = useState(null);
    const [inputValueAgence, setValueAgence] = useState('');
    const tableRef = useRef();
    const [req, setReq] = useState({
        DateDebut: '',
        DateFin: '',
        agence: '',
    });
    const navigate = useNavigate();

    const supprimerPassager = (Ticket) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de supprimer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/passager/' + Ticket.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getTickets();
                    }).catch(err => {
                        const response = err.response;
                        if (response.status === 401) {
                            setToken(null);
                        }
                        else {
                            if (response.status === 422) {
                                setErrors(response.data.errors);
                                setLoading(false);
                                toast.error('Une Erreur est survenue', {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            } else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }

                        }
                    })
            }
        });
    }

    const supprimerColis = (Ticket) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de supprimer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/colis/' + Ticket.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getTickets();
                    }).catch(err => {
                        const response = err.response;
                        if (response.status === 401) {
                            setToken(null);
                        }
                        else {
                            if (response.status === 422) {
                                setErrors(response.data.errors);
                                setLoading(false);
                                toast.error('Une Erreur est survenue', {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            } else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }

                        }
                    })
            }
        });
    }

    useEffect(() => {
        if (user.role != null && (user.role.name === 'admin')) {
            getAgences();
        }
    }, [])

    const getAgences = () => {
        axiosClient.get('/agences')
            .then((data) => {
                setAgences(data.data.agences);
            })
    }


    const closeAddTicket = () => {
        setShowAddTicket(false);
    }

    const closeAddColis = () => {
        setShowAddColis(false);
    }

    

    const closeShowTicket = () => {
        setShowDetailTicket(false);
        setSelectedTicket(null);
    }

    const closeShowColis = () => {
        setShowDetailColis(false);
        setSelectedColis(null);
    }

    const handleInputChangeAgence = value => {
        setValueAgence(value);
    };

    // handle selection


    // handle selection
    const handleChangeAgence = value => {
        setSelectedAgence(value);
        setReq({ ...req, agence: value.id });

    }
    const getTickets = () => {
        setLoading(true);
        axiosClient.get('/tickets?DateDebut=' + req.DateDebut + '&DateFin=' + req.DateFin + '&agence=' + req.agence)
            .then(response => {
                setTickets(response.data.passagers);
                setColis(response.data.colis);
                setLoading(false);
                setErrors(null);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }



    // const addDist = () => {
    //     if (selectedCaisse === null) {
    //         Swal.fire({
    //             title: "Alert",
    //             text: "Veillez sélectionner une caisse!",
    //             icon: "warning",
    //             confirmButtonColor: "#2196F3",
    //             confirmButtonText: "OK!",
    //         });
    //     } else {
    //         setShowAddDistTicket(true);
    //     }
    // }
    const closeAddDistTicket = () => {
        setShowAddDistTicket(false);

    }


    return (
        <>
            <Header title="Tickets" />
            {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
            }
            <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between items-center'>
                <Input label="Date debut" type="date" value={req.DateDebut} onChange={ev => setReq({ ...req, DateDebut: ev.target.value })} required="required" />
                <Input label="Date fin" type="date" value={req.DateFin} onChange={ev => setReq({ ...req, DateFin: ev.target.value })} required="required" />
                {user.role && (user.role.name === 'admin' || user.role.name === 'controleur' || user.role.name === 'dist') ?
                    <>
                        <div className='flex-col w-full justify-evenly '>
                            <div className="flex-col w-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">Agence <span className='text-red-500'> *</span></label>
                                <Select className='mt-2 outline-1 ' placeholder="Selectionner un agence" cacheOptions defaultOptions value={selectedAgence} getOptionLabel={e => e.nom} getOptionValue={e => e.id} options={agences} onInputChange={handleInputChangeAgence} onChange={handleChangeAgence} />
                            </div>
                        </div>
                    </>
                    :
                    <></>
                }
                <div className=' flex gap-2 mt-8'>
                    <Button onClick={() => getTickets()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
                    {/* <DownloadTableExcel
                        filename="Tickets"
                        sheet="Tickets"
                        currentTableRef={tableRef.current}
                    >
                        <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />

                    </DownloadTableExcel> */}
                </div>
            </div>
            <div className="flex-col gap-2">
                <div className="flex-col ">
                    {
                        user.role && user.role.name === 'agent' ? <div className="flex my-4">
                            <Button onClick={() => setShowAddTicket(true)} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Passager" textColor="text-white" />
                        </div>
                            : <></>
                    }
                    {
                        user.role && (user.role.name === 'admin') ? <div className="flex my-4">

                        </div>
                            : <></>
                    }
                    <div className="flex my-2 w-full">
                        <h1 className='text-red-500 font-bold'>Total: <NumberFormat valeur={tickets.reduce((n, { montantTotal }) => n + parseFloat(montantTotal), 0)} /> MRU</h1>
                    </div>
                    <div className='flex mt-3 overflow-auto'>
                        <table ref={tableRef} className='table-auto' >
                            <thead>
                                <tr>
                                    <th>Destination </th>
                                    <th>Nom</th>
                                    <th>Date</th>
                                    <th>Date.dep</th>
                                    <th>O.Bus</th>
                                    <th>P.Bag</th>
                                    <th>Montant Total</th>
                                    {user.role && (user.role.name === 'admin') ?
                                        <th>User</th>
                                        : <></>

                                    }
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="6"><Loading /></td></tr></tbody> :
                                <tbody className='text-sm'>
                                    {tickets.map(o => (
                                        <tr className={o.etat===0 ? 'bg-red-400' : 'bg-white'}>
                                            <td>{o.agenceDestination.nom}</td>
                                            <td>{o.nom}</td>
                                            <td>{o.created_at}</td>
                                            <td>{o.dateDepart}</td>
                                            <td>{o.ordreBus}</td>
                                            <td>{o.poidBagage}</td>
                                            <td>{o.montantTotal}</td>
                                            {user.role && (user.role.name === 'admin') ?
                                                <td>{o.user}</td>
                                                : <></>

                                            }
                                            <td>
                                                <div className="flex gap-2">
                                                    <ActionButton onClick={() => {
                                                        setSelectedTicket(o);
                                                        setShowDetailTicket(true);
                                                    }} color="bg-green-500" icon={<FaEye />} textColor="text-white" title="Voir" />
                                                    {user.role && (user.role.name === 'admin') ?
                                                        <ActionButton onClick={() => {
                                                            supprimerPassager(o);
                                                        }} color="bg-red-500" icon={<GiCancel />} textColor="text-white" title="Annuler" />
                                                        : <></>

                                                    }

                                                </div>
                                            </td>
                                        </tr>
                                    ))

                                    }
                                </tbody>
                            }

                        </table>
                    </div>
                </div>
                <div className="flex w-full py-1 my-2 bg-slate-200">

                </div>
                <div className="flex-col ">
                    {
                        user.role && user.role.name === 'agent' ? <div className="flex my-4">
                            <Button onClick={() => setShowAddColis(true)} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Colis" textColor="text-white" />
                        </div>
                            : <></>
                    }
                    {
                        user.role && (user.role.name === 'admin') ? <div className="flex my-4">

                        </div>
                            : <></>
                    }
                    <div className="flex my-2 w-full">
                        <h1 className='text-red-500 font-bold'>Total: <NumberFormat valeur={colis.reduce((n, { montant }) => n + parseFloat(montant), 0)} /> MRU</h1>
                    </div>
                    <div className='flex mt-3 overflow-auto'>
                        <table ref={tableRef} className='table-auto' >
                            <thead>
                                <tr>
                                    <th>Code </th>
                                    <th>Date </th>
                                    <th>Date.dep</th>
                                    <th>O.Bus</th>
                                    <th>Tel Exp</th>
                                    <th>Tel Rec</th>
                                    <th>Dest</th>
                                    <th>P.Bag</th>
                                    <th>Montant</th>
                                    {user.role && (user.role.name === 'admin') ?
                                        <th>User</th>
                                        : <></>

                                    }
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="6"><Loading /></td></tr></tbody> :
                                <tbody className='text-sm'>
                                    {colis.map(o => (
                                        <tr className={o.etat===0 ? 'bg-red-400' : o.etat===1 ? 'bg-white' : 'bg-green-300'}>
                                            <td>{o.code}</td>
                                            <td>{o.created_at}</td>
                                            <td>{o.dateDepart}</td>
                                            <td>{o.ordreBus}</td>
                                            <td>{o.telExpediteur}</td>
                                            <td>{o.telReceveur}</td>
                                            <td>{o.agenceDestination.nom}</td>
                                            <td>{o.poid}</td>
                                            <td>{o.montant}</td>
                                            {user.role && (user.role.name === 'admin') ?
                                                <td>{o.user}</td>
                                                : <></>

                                            }
                                            <td>
                                                <div className="flex gap-2">
                                                    <ActionButton onClick={() => {
                                                        setSelectedColis(o);
                                                        setShowDetailColis(true);
                                                        
                                                    }} color="bg-green-500" icon={<FaEye />} textColor="text-white" title="Voir" />
                                                    {user.role && (user.role.name === 'admin') ?
                                                        <ActionButton onClick={() => {
                                                            supprimerColis(o);
                                                        }} color="bg-red-500" icon={<GiCancel />} textColor="text-white" title="Annuler" />
                                                        : <></>

                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            }

                        </table>
                    </div>
                </div>
            </div>
            {
                showAddTicket ? <AddTicket onClose={closeAddTicket} /> : <></>
            }
            {
                showAddColis ? <AddColis onClose={closeAddColis} /> : <></>
            }
            {
                showDetailTicket ? <EditTicket editedTicket={selectedTicket} onClose={closeShowTicket}/> : <></> 
            }
            {
                showDetailColis ? <EditColis editedColis={selectedColis} onClose={closeShowColis}/> : <></>
            }

        </>
    )
}

export default Tickets